import { useTranslation } from "react-i18next";

import { useRootAndSubState } from "../state/StateProvider";

export const SystemInformation = () => {
    const { state } = useRootAndSubState("operator", "systemInformation");
    const { systemInformation } = state;
    const { t } = useTranslation();

    return (
        <div className="m-4 flex h-full flex-col gap-4">
            <div className="text-2xl font-semibold">
                {t("operator.systemInformation.title")}
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.deviceId")}
                </div>
                <div className="italic">{systemInformation.deviceId}</div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.workstationId")}
                </div>
                <div className="italic">{systemInformation.workstationId}</div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.terminalId")}
                </div>
                <div className="italic">{systemInformation.terminalId}</div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.terminalIp")}
                </div>
                <div className="italic">
                    {systemInformation.terminalIp || t("unknown")}
                </div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.runningVersion")}
                </div>
                <div className="italic">{systemInformation.runningVersion}</div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.storeId")}
                </div>
                <div className="italic">{systemInformation.storeId}</div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.operationMode")}
                </div>
                <div className="italic">
                    {systemInformation.operationMode || t("unknown")}
                </div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.productPresetIds")}
                </div>
                <div className="italic">
                    {systemInformation.productPresetIds.join(", ")}
                </div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.kitchenMenuId")}
                </div>
                <div className="italic">
                    {systemInformation.kitchenMenuId || t("unknown")}
                </div>
            </div>
            <div className="flex flex-col">
                <div className="font-medium">
                    {t("operator.systemInformation.tenant")}
                </div>
                <div className="italic">{systemInformation.tenant}</div>
            </div>
        </div>
    );
};
