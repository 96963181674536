import { useTranslation } from "react-i18next";

import { getDate } from "../../date";
import { useRootState } from "../state/StateProvider";

export const OperatorMenu = () => {
    const { t } = useTranslation();
    const {
        state: { accessibilityMode },
        dispatch
    } = useRootState("operator");

    return (
        <div data-testid="cartComponent" className="flex h-full flex-col">
            <div className="m-4 mb-3 mt-6 text-3xl font-bold text-primary-800">
                {t("operatorMode")}
            </div>
            <div className="flex h-full flex-col gap-3 p-3">
                <div className="grid grid-cols-2 gap-2 px-3">
                    {[
                        {
                            label: t("order", { count: 2 }),
                            action: () => dispatch({ kind: "showOrders" })
                        },
                        {
                            label: t("insight", { count: 2 }),
                            action: () =>
                                dispatch({
                                    kind: "showInsights",
                                    date: getDate(new Date()),
                                    groupBy: "scoId"
                                })
                        },
                        {
                            label: t("operator.hardware.title", { count: 2 }),
                            action: () =>
                                dispatch({
                                    kind: "showHardware"
                                })
                        },
                        {
                            label: t("operator.systemInformation.title"),
                            action: () =>
                                dispatch({
                                    kind: "showSystemInformation"
                                })
                        }
                    ].map(({ label, action }) => (
                        <button
                            key={label}
                            className={`
                                    press-effect
                                    grid
                                    grid-rows-1
                                    py-20
                                    ${
                                        accessibilityMode
                                            ? "grid-cols-2 gap-x-1"
                                            : "grid-cols-1"
                                    }
                                    place-items-center
                                    rounded
                                    bg-primary-100
                                    px-4
                                    text-center text-lg font-semibold
                                    text-primary-800
                                `}
                            onClick={() => action()}
                        >
                            {t(label)}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
