import { useState } from "react";
import { useTranslation } from "react-i18next";

import kitchenMenuLogo from "../../../assets/kitchen-menu.svg";
import placeholderImage from "../../../assets/placeholder.png";
import {
    KitchenCategory,
    KitchenMenu as Menu,
    KitchenProduct
} from "../../types/KitchenMenu";
import Price from "../../types/Price";
import { format } from "../../utils/Price";
import { translate } from "../../utils/translations";
import { useRootState } from "../state/StateProvider";
import * as svg from "../svg";

export const KitchenMenuDialog = ({
    cartQuantity,
    cartGrossPrice,
    cartTotalPrice,
    kitchenMenu,
    onCancel
}: {
    cartQuantity: number;
    cartGrossPrice: Price;
    cartTotalPrice: Price;
    kitchenMenu: Menu;
    onCancel: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const {
        state: { accessibilityMode },
        dispatch
    } = useRootState("kitchen");

    const [currentFilter, setFilter] = useState<number>(0);

    const allCategories = getHardcodedKitchenCategories(kitchenMenu.categories);

    const products = allCategories[currentFilter].products;

    const CategoryTab = ({ index }: { index: number }) => {
        return (
            <button
                data-testid={`category-${index}`}
                onClick={() => {
                    setFilter(index);
                }}
                className={`
                    w-40 min-w-fit flex-[0_0_3.5rem] rounded p-2 text-left text-xs font-bold capitalize
                    ${
                        index === currentFilter
                            ? "bg-white text-primary-500"
                            : "bg-primary-300 text-white"
                    }
                `}
            >
                {translate(allCategories[index].name, i18n)}
            </button>
        );
    };

    return (
        <>
            <div
                data-testid="kitchenMenu"
                className="z-10 flex h-full flex-col gap-4 bg-primary-500 p-3 pb-0"
            >
                <img src={kitchenMenuLogo} className="mx-auto h-20 w-auto" />
                <div className="flex w-full flex-1 gap-3 overflow-x-clip overflow-y-scroll">
                    <div
                        data-testid="categories"
                        className="-mx-2 flex flex-col gap-2 overflow-y-auto px-2"
                    >
                        {allCategories.map((_, index) => (
                            <CategoryTab key={index} index={index} />
                        ))}
                    </div>
                    <div
                        role="list"
                        data-testid="products"
                        className="grid flex-1 grid-cols-2 content-start gap-2 overflow-y-auto [scrollbar-width:none]"
                    >
                        {products.map((product) => (
                            <button
                                disabled={!product.available}
                                role="listitem"
                                data-testid={`product-${product.barcode}`}
                                key={`product-${product.id}-${product.barcode}`}
                                onClick={() =>
                                    dispatch({
                                        kind: "showKitchenProductSelector",
                                        product
                                    })
                                }
                                className={`
                                    press-effect flex
                                    ${accessibilityMode ? "h-32" : "h-44"}
                                    flex-col items-center
                                    gap-2 p-4 pb-2
                                    ${!product.available ? "opacity-50" : ""}
                                    rounded
                                    bg-white
                                    text-center text-xs font-semibold
                                    text-primary-800
                                `}
                            >
                                {product.originalPrice && (
                                    <span className="absolute right-0 top-0 bg-[#E5005B] p-2">
                                        {svg.discount}
                                    </span>
                                )}
                                <img
                                    src={product.imageUrl ?? placeholderImage}
                                    className={
                                        accessibilityMode ? "h-12" : "h-20"
                                    }
                                />
                                <span className="w-full overflow-hidden text-ellipsis capitalize text-primary-600">
                                    {translate(
                                        product.name,
                                        i18n
                                    ).toLowerCase()}
                                </span>
                                {product.available && product.price && (
                                    <span>
                                        {product.originalPrice && (
                                            <span className="grow content-end pr-1 font-normal text-primary-400 line-through">
                                                {format(product.originalPrice)}
                                            </span>
                                        )}
                                        <span className="grow content-end font-semibold text-primary-400">
                                            {format(product.price)}
                                        </span>
                                    </span>
                                )}
                                {!product.available && (
                                    <span
                                        className={`
                                            grow content-end
                                            font-normal text-primary-400
                                        `}
                                    >
                                        {t("kitchenMenu.productUnavailable")}
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="-mx-4 bg-primary-200 p-3 text-center">
                    <button
                        data-testid="closeKitchenMenu"
                        onClick={onCancel}
                        className="flex w-full flex-col items-center rounded bg-primary-500 p-2 text-lg text-white"
                    >
                        <span className="text-md font-semibold">
                            {t("kitchenMenu.backToCart")}
                        </span>
                        <span className="text-xs text-primary-200 opacity-50">
                            {`${cartQuantity} ${t("product", {
                                count: cartQuantity
                            })} - `}
                            {cartGrossPrice.value !== cartTotalPrice.value && (
                                <span className="pr-1 line-through">
                                    {format(cartGrossPrice)}
                                </span>
                            )}
                            {format(cartTotalPrice)}
                        </span>
                    </button>
                </div>
            </div>
        </>
    );
};

function getHardcodedKitchenCategories(categories: KitchenCategory[]) {
    const allProducts = categories.flatMap((c) => c.products);

    const hardcodedKitchenComboProducts: KitchenProduct[] = [
        {
            id:
                allProducts.find((p) => p.barcode === "7611546032658")?.id ??
                "",
            available: allProducts
                .filter((p) =>
                    ["7611546032658", "7611546032573"].includes(p.barcode)
                )
                .every((p) => p.available),
            barcode: "7611546032573",
            name: {
                ch: "Hamburger-Combo",
                de: "Hamburger-Combo",
                fr: "Hamburger-Combo",
                en: "Hamburger-Combo",
                it: "Hamburger-Combo"
            },
            allergens: { ch: "-", de: "-", fr: "-", en: "-", it: "-" },
            nutritionalInformation: {
                ch: "-",
                de: "-",
                fr: "-",
                en: "-",
                it: "-"
            },
            imageUrl:
                "https://storage.googleapis.com/reto-public-storage-test/kitchen-product-images/combo-hamburger-wedges.png",
            addOns:
                allProducts.find((p) => p.barcode === "7611546032580")
                    ?.addOns ?? [],
            upsellingConfigurations:
                allProducts.find((p) => p.barcode === "7611546032658")
                    ?.upsellingConfigurations ?? [],
            price: { value: "10.9", currency: "chf" },
            comboBarcodes: ["7611546032658"]
        },
        {
            id:
                allProducts.find((p) => p.barcode === "7611546032658")?.id ??
                "",
            available: allProducts
                .filter((p) =>
                    ["7611546032658", "7611546032580"].includes(p.barcode)
                )
                .every((p) => p.available),
            barcode: "7611546032580",
            name: {
                ch: "Cheeseburger-Combo",
                de: "Cheeseburger-Combo",
                fr: "Cheeseburger-Combo",
                en: "Cheeseburger-Combo",
                it: "Cheeseburger-Combo"
            },
            allergens: { ch: "-", de: "-", fr: "-", en: "-", it: "-" },
            nutritionalInformation: {
                ch: "-",
                de: "-",
                fr: "-",
                en: "-",
                it: "-"
            },
            imageUrl:
                "https://storage.googleapis.com/reto-public-storage-test/kitchen-product-images/combo-cheeseburger-wedges.png",
            addOns:
                allProducts.find((p) => p.barcode === "7611546032580")
                    ?.addOns ?? [],
            upsellingConfigurations:
                allProducts.find((p) => p.barcode === "7611546032658")
                    ?.upsellingConfigurations ?? [],
            price: { value: "11.4", currency: "chf" },
            comboBarcodes: ["7611546032658"]
        },
        {
            id:
                allProducts.find((p) => p.barcode === "7611546032658")?.id ??
                "",
            available: allProducts
                .filter((p) =>
                    ["7611546032658", "7611546032597"].includes(p.barcode)
                )
                .every((p) => p.available),
            barcode: "7611546032597",
            name: {
                ch: "Hamburger-Combo (Veggie)",
                de: "Hamburger-Combo (Veggie)",
                fr: "Hamburger-Combo (Veggie)",
                en: "Hamburger-Combo (Veggie)",
                it: "Hamburger-Combo (Veggie)"
            },
            allergens: { ch: "-", de: "-", fr: "-", en: "-", it: "-" },
            nutritionalInformation: {
                ch: "-",
                de: "-",
                fr: "-",
                en: "-",
                it: "-"
            },
            imageUrl:
                "https://storage.googleapis.com/reto-public-storage-test/kitchen-product-images/combo-veggie-hamburger-wedges.png",
            addOns:
                allProducts.find((p) => p.barcode === "7611546032580")
                    ?.addOns ?? [],
            upsellingConfigurations:
                allProducts.find((p) => p.barcode === "7611546032658")
                    ?.upsellingConfigurations ?? [],
            price: { value: "10.9", currency: "chf" },
            comboBarcodes: ["7611546032658"]
        },
        {
            id:
                allProducts.find((p) => p.barcode === "7611546032658")?.id ??
                "",
            available: allProducts
                .filter((p) =>
                    ["7611546032658", "7611546032603"].includes(p.barcode)
                )
                .every((p) => p.available),
            barcode: "7611546032603",
            name: {
                ch: "Cheeseburger-Combo (Veggie)",
                de: "Cheeseburger-Combo (Veggie)",
                fr: "Cheeseburger-Combo (Veggie)",
                en: "Cheeseburger-Combo (Veggie)",
                it: "Cheeseburger-Combo (Veggie)"
            },
            allergens: { ch: "-", de: "-", fr: "-", en: "-", it: "-" },
            nutritionalInformation: {
                ch: "-",
                de: "-",
                fr: "-",
                en: "-",
                it: "-"
            },
            imageUrl:
                "https://storage.googleapis.com/reto-public-storage-test/kitchen-product-images/combo-veggie-cheeseburger-wedges.png",
            addOns:
                allProducts.find((p) => p.barcode === "7611546032580")
                    ?.addOns ?? [],
            upsellingConfigurations:
                allProducts.find((p) => p.barcode === "7611546032658")
                    ?.upsellingConfigurations ?? [],
            price: { value: "11.4", currency: "chf" },
            comboBarcodes: ["7611546032658"]
        }
    ];
    const allCategories: KitchenCategory[] = categories.concat({
        name: {
            ch: "Deals",
            de: "Deals",
            fr: "Deals",
            en: "Deals",
            it: "Deals"
        },
        products: hardcodedKitchenComboProducts
    });

    return allCategories;
}
