import { AnimatePresence } from "framer-motion";

import { KitchenMenuDialog } from "./KitchenMenuDialog";
import { KitchenProductSelectorDialog } from "./KitchenProductSelectorDialog";
import { SlideUp } from "../Animations";
import useBarcodeScanner from "../hooks/useBarcodeScanner";
import { useOnScanProduct } from "../hooks/useOnScanProduct";
import { ProductNotFoundDialog } from "../ProductNotFound";
import { SpinnerOverlay } from "../Spinner";
import { useRootState } from "../state/StateProvider";
import { useDelayedCondition } from "../useDelayedCondition";

export const Kitchen = () => {
    const { state, dispatch } = useRootState("kitchen");
    const { accessibilityMode, subState } = state;

    const isLoading = state.subState.kind === "loading";
    const displayLoadingIndicator = useDelayedCondition(isLoading, 2000);

    const onScan = useOnScanProduct();
    useBarcodeScanner({ onScan });

    return (
        <AnimatePresence>
            (
            <div data-theme="kitchen">
                <SlideUp
                    key="select"
                    className={`fixed inset-x-0 bottom-0 ${
                        accessibilityMode ? "top-80" : "top-0"
                    } z-10 rounded-t-xl`}
                >
                    {displayLoadingIndicator && <SpinnerOverlay />}
                    {subState.kind === "error" && (
                        <ProductNotFoundDialog
                            onOk={() =>
                                dispatch({ kind: "hideProductNotFound" })
                            }
                        />
                    )}
                    <KitchenMenuDialog
                        cartQuantity={state.cart.lineItems.length}
                        cartGrossPrice={state.cart.grossPrice}
                        cartTotalPrice={state.cart.totalPrice}
                        kitchenMenu={state.menu}
                        onCancel={() => dispatch({ kind: "hideKitchenMenu" })}
                    />
                    {state.subState.kind === "productSelector" && (
                        <KitchenProductSelectorDialog
                            product={state.subState.product}
                            onConfirm={(
                                productId,
                                barcode,
                                quantity,
                                addOnOptionIds,
                                comboBarcodes,
                                upsellingProductBarcodes
                            ) => {
                                dispatch({
                                    kind: "addKitchenProduct",
                                    productId,
                                    barcode,
                                    quantity,
                                    addOnOptionIds,
                                    comboBarcodes,
                                    upsellingProductBarcodes
                                });
                            }}
                            onCancel={() =>
                                dispatch({
                                    kind: "hideKitchenProductSelector"
                                })
                            }
                        />
                    )}
                </SlideUp>
            </div>
            )
        </AnimatePresence>
    );
};
