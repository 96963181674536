import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Order } from "../../types/Order";
import * as Price from "../../utils/Price";
import { Button } from "../Button";
import { requireSubState } from "../state/Common";
import { useRootAndSubState, useRootState } from "../state/StateProvider";
import { chevronLeft, chevronRight } from "../svg";

export const orderListPageSize = 8;

export const OrderList = () => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useRootAndSubState("operator", "orders");

    const { orders, totalCount, date } = state;

    const onPrintOrder = async (orderId: string) => {
        await dispatch({ kind: "printReceipt", orderId });
    };

    return (
        <div className="m-4 flex flex-col gap-4">
            <div className="text-3xl font-semibold text-primary-900">
                {t("orderList.title")}
            </div>
            <div className="text-xl text-primary-900">
                <input
                    type="date"
                    lang={i18n.language}
                    value={date}
                    onChange={(e) =>
                        dispatch({
                            kind: "loadMoreOrders",
                            date: e.target.value,
                            offset: 0
                        })
                    }
                />
            </div>
            <div className="flex flex-col items-center gap-2">
                {orders.length > 0 && (
                    <>
                        {orders.map((order) => (
                            <OrderItem
                                key={order.id}
                                order={order}
                                onPrintOrder={onPrintOrder}
                            />
                        ))}

                        <div className="mt-4" />

                        <OrderPagination count={totalCount} />
                    </>
                )}
            </div>
        </div>
    );
};

export const BackToScreensaver = () => {
    const { t } = useTranslation();
    const { dispatch } = useRootState("operator");

    return (
        <Button onClick={() => dispatch({ kind: "reset" })} label={t("back")} />
    );
};

const Timestamp = ({ timestamp }: { timestamp: Date }) => {
    return (
        <div className="font-semibold">
            {timestamp.toLocaleTimeString([], { timeStyle: "short" })}
        </div>
    );
};

const MaskedPaymentMethod = ({
    className,
    lastCardDigits
}: {
    className?: string;
    lastCardDigits: Order["paymentMethod"]["lastDigits"];
}) => {
    if (!lastCardDigits) {
        return <div className={className}>----</div>;
    }

    const isShortVersion = lastCardDigits.length === 4;
    const digits = isShortVersion
        ? lastCardDigits
        : lastCardDigits.slice(
              lastCardDigits.length - 4,
              lastCardDigits.length
          );

    return (
        <div className={`whitespace-nowrap ${className}`}>
            **** **** **** {digits}
        </div>
    );
};

const Total = ({ price }: { price: Order["totalPrice"] }) => {
    return <div className="font-semibold">{Price.format(price)}</div>;
};

const PrintReceiptButton = ({
    id,
    onPrintOrder
}: {
    id: Order["id"];
    onPrintOrder: (id: string) => void;
}) => {
    return (
        <Button label={t("printReceipt")} onClick={() => onPrintOrder(id)} />
    );
};

const OrderDetailsButton = ({
    isOpen,
    toggle
}: {
    isOpen: boolean;
    toggle: () => void;
}) => {
    const { t } = useTranslation();
    const label = `${t("orderList.details")} ${isOpen ? "▾" : "▸"}`;

    return (
        <button
            onClick={() => toggle()}
            className="whitespace-nowrap font-semibold text-primary-500"
        >
            {label}
        </button>
    );
};

const OrderItem = ({
    order,
    onPrintOrder
}: {
    order: Order;
    onPrintOrder: (id: string) => void;
}) => {
    const [isOrderDetailsOpen, setOrderDetailsOpen] = useState<boolean>(false);

    return (
        <div className="w-full text-sm">
            <div className="flex items-center justify-between gap-4 rounded bg-gray-100 p-4">
                <div className="grid w-1/2 grid-cols-4">
                    <Timestamp timestamp={new Date(order.orderCreatedAt)} />
                    <MaskedPaymentMethod
                        className="col-span-2"
                        lastCardDigits={order.paymentMethod.lastDigits}
                    />
                    <Total price={order.totalPrice} />
                </div>

                <div className="flex items-center gap-4">
                    <PrintReceiptButton
                        id={order.id}
                        onPrintOrder={onPrintOrder}
                    />
                    <OrderDetailsButton
                        isOpen={isOrderDetailsOpen}
                        toggle={() => setOrderDetailsOpen(!isOrderDetailsOpen)}
                    />
                </div>
            </div>
            {isOrderDetailsOpen && (
                <div className="bg-primary-100 p-4 text-primary-400">
                    {order.positions.map((p, index) => {
                        switch (p.kind) {
                            case "single":
                                return (
                                    <span
                                        key={index}
                                        className="flex justify-between"
                                    >
                                        {p.quantity}x {p.name}
                                        <span>{Price.format(p.price)}</span>
                                    </span>
                                );
                            case "group":
                                return (
                                    <div key={index}>
                                        <span className="flex justify-between">
                                            {p.quantity}x {p.name}
                                            <span>{Price.format(p.price)}</span>
                                        </span>
                                        {p.lineItems.map((li, i) => (
                                            <span
                                                key={`li-${i}`}
                                                className="flex justify-between pl-4"
                                            >
                                                {li.quantity}x {li.name}
                                                <span>—</span>
                                            </span>
                                        ))}
                                    </div>
                                );
                        }
                    })}
                </div>
            )}
        </div>
    );
};

const OrderPagination = ({ count }: { count: number }) => {
    const { state, dispatch } = useRootState("operator");

    const { offset, date } = requireSubState(state, "operator", "orders");

    const currentPage = Math.floor(offset / orderListPageSize);

    const loadPage = (page: number) =>
        dispatch({
            kind: "loadMoreOrders",
            date,
            offset: page * orderListPageSize
        });

    const loadNextPage = () => loadPage(currentPage + 1);
    const loadPreviousPage = () => loadPage(currentPage - 1);

    const pages = [...Array(Math.ceil(count / orderListPageSize)).keys()];
    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === pages[pages.length - 1];

    return (
        <div className="flex w-fit items-center justify-between gap-4">
            <button
                className={`${isFirstPage && "opacity-30"}`}
                disabled={isFirstPage}
                onClick={loadPreviousPage}
            >
                {chevronLeft}
            </button>
            {pages.map((page) => (
                <button
                    key={page}
                    onClick={() => loadPage(page)}
                    className={`
					flex
					h-8
					w-8
					items-center
					justify-center
					rounded
					p-6
					${currentPage === page ? "bg-red-100" : "bg-gray-100"}`}
                >
                    {page + 1}
                </button>
            ))}
            <button
                className={`${isLastPage && "opacity-20"}`}
                disabled={isLastPage}
                onClick={loadNextPage}
            >
                {chevronRight}
            </button>
        </div>
    );
};
